<template>
  <div class="member-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/index' }">会议壳</el-breadcrumb-item>
      <el-breadcrumb-item>会员管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-bar">
      <div class="left">
        <el-form :inline="true" :model="query" class="search-form-inline">
          <el-form-item>
            <el-input v-model="query.title" placeholder="请输入会员昵称或用户名" clearable @clear="getDataList"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getDataList">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="page-content" v-if="dataList.length > 0">
      <el-table :data="dataList" border style="width: 100%" max-height="500">
        <el-table-column prop="id" label="#" width="80"></el-table-column>
        <el-table-column prop="m_id" label="混合ID" width="120"></el-table-column>
        <el-table-column prop="title" label="所属会议"></el-table-column>
        <el-table-column prop="username" label="用户名"></el-table-column>
        <el-table-column prop="openid" label="openid"></el-table-column>
        <el-table-column prop="nick" label="昵称"></el-table-column>
        <el-table-column prop="avatar" label="头像">
          <template slot-scope="scope">
            <el-avatar :src="scope.row.avatar"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="platform" label="来源平台">
          <template slot-scope="scope">
            <el-tag type="" effect="dark" v-if="scope.row.platform === 1">公众号</el-tag>
            <el-tag type="success" effect="dark" v-if="scope.row.platform === 2">小程序</el-tag>
            <el-tag type="warning" effect="dark" v-if="scope.row.platform === 3">网页</el-tag>
            <el-tag type="info" effect="dark" v-if="scope.row.platform === 4">其他</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="avatar" label="上次登录时间">
          <template slot-scope="scope">
            {{datafromatfull(scope.row.last_time)}}
          </template>
        </el-table-column>
      </el-table>
      <div class="footer-pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.currentPage" :page-sizes="[20, 50, 100]" :page-size="query.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="query.total"></el-pagination>
      </div>
    </div>
    <div class="no-list" v-else>
      <el-empty :image-size="200"></el-empty>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      query: {
        title: '',
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      dataList: []
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    // 获取已添加全景项目列表
    async getDataList () {
      const { data: res } = await this.$http.get('shell-member-list', { params: this.query })
      if (res.status === 200) {
        this.dataList = res.data.list.data
        this.query.total = Number(res.data.list.total)
        this.query.currentPage = Number(res.data.list.current_page)
        this.query.pageSize = Number(res.data.pageSize)
      } else {
        this.$message.error(res.msg)
      }
    },
    setAuth (mid) {

    },
    // 更改每页显示数量
    handleSizeChange (val) {
      this.query.pageSize = val
      this.getDataList()
    },
    // 更改页码
    handleCurrentChange (val) {
      this.query.currentPage = val
      this.getDataList()
    },
    datafromatfull (time) {
      return this.$moment.unix(time).format('YYYY-MM-DD HH:mm')
    }
  }
}
</script>

<style scoped>
.search-bar{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-form-inline .el-form-item{
  margin-bottom: 0;
}
.add-form{
  padding: 0 40px;
  text-align: left;
}
/deep/.el-form--label-top .el-form-item__label{
  padding: 0;
}
.el-form-item{
  margin-bottom: 8px;
}
.el-descriptions{
  padding: 0 40px;
}
.el-radio-group .el-radio{
  margin-bottom: 10px;
}
.footer-pagination{
  background-color: #FFFFFF;
  padding: 10px;
}
</style>
